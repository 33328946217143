import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index-grid.scss';
import './overrides.scss';
import reportWebVitals from './reportWebVitals';
import Loader from "./components/Loader/Loader";

const I18Provider = lazy(() => import('./I18Provider'));


ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<Loader />}>
            <I18Provider />
        </Suspense>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
