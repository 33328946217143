import React from 'react';
import { ReactComponent as LoaderSvg } from './loader.svg';

function Loader() {
    return (
        <div id={'loader'} className={'loader-component'}>
            <LoaderSvg />
        </div>
    );
}

export default Loader;